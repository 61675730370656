import { IIconProps, Icon } from "native-base";
import * as React from "react";
import { G, Path, Defs, ClipPath } from "react-native-svg";

export const BlockAddIcon = (props: IIconProps) => (
  <Icon fill="none" viewBox="0 0 16 16" color="#001A19" {...props}>
    <G
      stroke="currentColor"
      strokeLinejoin="round"
      strokeWidth={1.5}
      clipPath="url(#a)"
      fill="none"
    >
      <Path strokeLinecap="round" d="M15 8a7 7 0 1 0-7 7" />
      <Path d="M13 3 3 13" />
      <Path strokeLinecap="round" d="M12.5 10v5M10 12.5h5" />
    </G>
    <Defs>
      <ClipPath id="a">
        <Path fill="#fff" d="M0 0h16v16H0z" />
      </ClipPath>
    </Defs>
  </Icon>
);
