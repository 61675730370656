import { IIconProps, Icon } from "native-base";
import * as React from "react";
import { G, Path, Defs, ClipPath } from "react-native-svg";

export const CalendarAddIcon = (props: IIconProps) => (
  <Icon fill="none" viewBox="0 0 16 16" {...props}>
    <G
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      clipPath="url(#a)"
      fill="none"
    >
      <Path d="M15 7V3.444C15 2.647 14.304 2 13.444 2H2.556C1.696 2 1 2.647 1 3.444v10.112C1 14.353 1.696 15 2.556 15H7M11 1v3M5 1v3M1 7h14M15 12.5h-5M12.5 10v5" />
    </G>
    <Defs>
      <ClipPath id="a">
        <Path fill="#fff" d="M0 0h16v16H0z" />
      </ClipPath>
    </Defs>
  </Icon>
);
