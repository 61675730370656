import { IIconProps, Icon } from "native-base";
import * as React from "react";
import { Path, G } from "react-native-svg";

export const DollarExemptIcon = (props: IIconProps) => (
  <Icon viewBox="0 0 12 12" fill="none" {...props}>
    <G clip-path="url(#clip0_1787_15556)">
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.34043 0.638298C6.34043 0.285776 6.05465 0 5.70213 0C5.34961 0 5.06383 0.285776 5.06383 0.638298V1.53191H4.55319C3.1431 1.53191 2 2.67502 2 4.08511C2 5.49519 3.1431 6.6383 4.55319 6.6383H5.06383V9.19149H2.6383C2.28578 9.19149 2 9.47726 2 9.82979C2 10.1823 2.28578 10.4681 2.6383 10.4681H5.06383V11.3617C5.06383 11.7142 5.34961 12 5.70213 12C6.05465 12 6.34043 11.7142 6.34043 11.3617V10.4681H6.85106C8.26115 10.4681 9.40426 9.32498 9.40426 7.91489C9.40426 6.5048 8.26115 5.3617 6.85106 5.3617H6.34043V2.80851H8.76596C9.11848 2.80851 9.40426 2.52273 9.40426 2.17021C9.40426 1.81769 9.11848 1.53191 8.76596 1.53191H6.34043V0.638298ZM5.06383 2.80851H4.55319C3.84815 2.80851 3.2766 3.38006 3.2766 4.08511C3.2766 4.79015 3.84815 5.3617 4.55319 5.3617H5.06383V2.80851ZM6.34043 6.6383V9.19149H6.85106C7.55611 9.19149 8.12766 8.61994 8.12766 7.91489C8.12766 7.20985 7.55611 6.6383 6.85106 6.6383H6.34043Z"
        fill="currentColor"
      />
      <Path
        d="M-1 13L13 -1"
        stroke="white"
        strokeWidth="4.5"
        strokeLinecap="round"
      />
      <Path
        d="M1 11L11 1"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
    </G>
  </Icon>
);
