import { IIconProps, Icon } from "native-base";
import * as React from "react";
import { G, Path, Defs, ClipPath } from "react-native-svg";

export const UnknownIcon = (props: IIconProps) => (
  <Icon viewBox="0 0 20 20" color="currentColor" {...props}>
    <G clipPath="url(#a)">
      <Path
        fill="#BDC7C7"
        fillRule="evenodd"
        d="M14.854 6.232 9.768 1.147a2.5 2.5 0 0 0-3.536 0L1.146 6.232a2.5 2.5 0 0 0 0 3.536l5.086 5.086a2.5 2.5 0 0 0 3.536 0l5.085-5.086a2.5 2.5 0 0 0 0-3.536Zm-7.85 3.469h1.413v-.73a.32.32 0 0 1 .053-.19.465.465 0 0 1 .174-.14l.002-.001c.293-.156.507-.28.809-.456l.05-.03c.214-.125.416-.242.557-.405.166-.193.238-.43.238-.764v-1.49c0-.765-.616-1.37-1.392-1.37H7.08c-.766 0-1.381.606-1.381 1.37v1.094h1.425v-.873c0-.147.114-.264.279-.264h1.203c.159 0 .268.11.268.264V6.72c0 .132-.017.174-.036.2-.025.036-.08.08-.237.164-.173.092-.29.153-.44.231H8.16c-.101.053-.22.114-.38.199-.263.135-.469.273-.601.474-.135.204-.176.443-.176.74v.973Zm-.056 2.174h1.547v-1.757H6.948v1.757Z"
        clipRule="evenodd"
      />
    </G>
    <Defs>
      <ClipPath id="a">
        <Path fill="#fff" d="M0 0h16v16H0z" />
      </ClipPath>
    </Defs>
  </Icon>
);
