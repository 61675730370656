import { IIconProps, Icon } from "native-base";
import * as React from "react";
import { G, Path, Defs, ClipPath } from "react-native-svg";

export const CircleCheckIcon = (props: IIconProps) => (
  <Icon fill="none" viewBox="0 0 16 16" color="currentColor" {...props}>
    <G clipPath="url(#a)">
      <Path
        fill="#1DAA75"
        fillRule="evenodd"
        d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16Zm4.03-9.97a.75.75 0 0 0-1.06-1.06L6.5 9.44 5.03 7.97a.75.75 0 0 0-1.06 1.06l2 2a.75.75 0 0 0 1.06 0l5-5Z"
        clipRule="evenodd"
      />
    </G>
    <Defs>
      <ClipPath id="a">
        <Path fill="#fff" d="M0 0h16v16H0z" />
      </ClipPath>
    </Defs>
  </Icon>
);
