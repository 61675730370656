import { IIconProps, Icon } from "native-base";
import * as React from "react";
import { Path } from "react-native-svg";

export const LessonsIcon = (props: IIconProps) => (
  <Icon viewBox="0 0 16 16" fill="transparent" fillOpacity="0" {...props}>
    <Path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="m1 5 2 6h10l2-6-4 2-3-5-3 5-4-2ZM4 14h8"
      fill="none"
    />
  </Icon>
);
