import React, { useCallback, useContext, useMemo } from "react";

import { ToastContext } from "./WhooshToastProvider";
import { ShowToastProps } from "./types";

export const useToast = () => {
  const context = useContext(ToastContext);

  const { add, remove } = context ?? {
    add: (toast: ShowToastProps) => {},
    remove: (toastId: string) => {},
  };

  const closeToast = useCallback(
    (toastId: string) => {
      remove(toastId);
    },
    [remove]
  );

  const showToast = React.useCallback(
    (args: ShowToastProps) => {
      add(args);
    },
    [add]
  );

  const returnValue = useMemo(() => {
    return { showToast, closeToast };
  }, [showToast, closeToast]);

  return returnValue;
};
