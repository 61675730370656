import { IIconProps, Icon } from "native-base";
import * as React from "react";
import { G, Path, Defs, ClipPath } from "react-native-svg";

export const RemoteLockIcon = (props: IIconProps) => (
  <Icon viewBox="0 0 24 24" fill="none" {...props}>
    <G fill="currentColor">
      <Path d="M2.336 13.15c0 5.992 4.85 10.85 10.832 10.85C19.15 24 24 19.142 24 13.15S19.15 2.3 13.168 2.3v5.187a5.658 5.658 0 0 1 5.653 5.663 5.658 5.658 0 0 1-5.653 5.663 5.66 5.66 0 0 1-5.654-5.663H2.336Z" />
      <Path d="M5.177 10.519h2.897a5.793 5.793 0 0 1 2.447-2.44V5.182c-2.61.852-4.472 2.669-5.344 5.337ZM10.521 0C5.234 1.05 1.056 5.225 0 10.52h2.616a10.86 10.86 0 0 1 7.905-7.903V0ZM11.476 11.43l-.026.026c-.5.5-.5 1.32 0 1.82l1.592 1.593c.499.5 1.316.5 1.815 0l.026-.025c.5-.5.5-1.32 0-1.82l-1.591-1.593c-.5-.5-1.317-.5-1.816 0Z" />
    </G>
    <Defs>
      <ClipPath id="a">
        <Path fill="#fff" d="M0 0h24v24H0z" />
      </ClipPath>
    </Defs>
  </Icon>
);

export default RemoteLockIcon;
